@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");

body,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: "Pretendard";
}
